import WordDictionary from "../components/WordDictionary"
import { useQuery } from '@apollo/client';
import { useEffect, useState, useRef, useContext } from 'react';
import { clusterByWeek } from "../utils/dashboard";
import Dictionary from "../Queries/Dictionary";
import dashboardContext from "../context/dashboard";
import VocabMetrics from "./VocabMetrics";

const Main = () => {
    const { selectedUser, community, role, communityId, studentsResult, clusteredData, selectedWeek, active, setClusteredData, setSelectedWeek, toggler, setToggler } = useContext(dashboardContext)
    const [sortedData, setSortedData] = useState({ word: 'asc', count: '', timestamp: '' })
    const viewRef = useRef()
    const { data: dictionaryResult, loading, error } = useQuery(Dictionary.getDictionary,
        { variables: { authorId: role === 'writer' ? studentsResult?.id : selectedUser ? selectedUser : studentsResult[0]?.id, communityId: communityId } })
    const [dictionary, setDictionary] = useState()
    const [selectedView, setSelectedView] = useState('')
    const [loadContent, setLoadContent] = useState(false)
    useEffect(() => {
        if (!error && !loading) {
            let clusterResponse
            const result = toggler ? dictionaryResult.dictionary?.phrases : dictionaryResult.dictionary?.words
            if (result) {
                if (selectedView) {
                    let dict = []
                    for (const item of result) {
                        for (const note of item.notes) {
                            if (note.viewId === selectedView)
                                dict.push(item)
                        }
                    }
                    setDictionary(dict)
                    clusterResponse = clusterByWeek(dict)

                }
                else {
                    console.log(result)
                    setDictionary(result)
                    clusterResponse = clusterByWeek(result)
                }
                setClusteredData(clusterResponse)
                setSelectedWeek(clusterResponse[0]?.weekStartDate)
            }
            else {
                setDictionary({})
                setClusteredData({})
            }
        }
        setLoadContent(false);
    }, [dictionaryResult, loading, error, selectedView, setClusteredData, setSelectedWeek, toggler])

    if (loading || !dictionary)
        return <div className="flex-center h-full">Loading....</div>
    return (
        <div className="content-main">
            <div className="flex-between-center">
                <h2>{active === 'Dictionary' ? 'Dictionary' : active === 'Progress' ? 'Weekly Progress' : active === 'Dashboard' ? 'Dashboard' : ''}</h2>
                <div className="flex-between-center">
                    <div className="toggle">
                        <div className="togglelabel word" style={{ color: !toggler && '#5C84A8', fontWeight: !toggler && 700 }}>Words</div>
                        <input type="checkbox" id="temp" checked={toggler} onChange={() => {
                            setLoadContent(true);
                            setToggler(!toggler);
                        }} />
                        <label className="togglelabel" htmlFor="temp" style={{ color: toggler && '#5C84A8', fontWeight: toggler && 700 }}>Phrases</label>
                    </div>
                    <select ref={viewRef} value={selectedView} onChange={(event) => {
                        setSelectedView(event.target.value);
                        setLoadContent(true);
                    }}>
                        <option value=''>All Views</option>
                        {community?.views.map((view, index) => <option key={index} value={view.id}>{view.title}</option>)}
                    </select>
                </div>
            </div>
            <div className="divider"></div>
            {loadContent ?
                <div className="flex-center h-full">Loading....</div>
                : active === 'Dashboard' ? <VocabMetrics dictionary={dictionary} /> : dictionary.length === 0 ?
                    <div className="flex-center h-full">No notes in this view</div>
                    :
                    <WordDictionary dictionary={active === 'Dictionary' ? dictionary : active === 'Progress' ? clusteredData.find(cluster => cluster.weekStartDate === selectedWeek).terms : []} updateDictionary={(updatedDictionary) => active === 'Dictionary' ? setDictionary(updatedDictionary) : active === 'Progress' ? setClusteredData(clusteredData.map(cluster => cluster.weekStartDate === selectedWeek ? { ...cluster, terms: updatedDictionary } : cluster)) : []} sortedData={sortedData} updateSortedData={(updatedSortedData) => setSortedData(updatedSortedData)} />}
        </div>
    );
}

export default Main;