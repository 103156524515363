import React, { useContext, useEffect, useRef, useState } from 'react';
import dashboardContext from '../context/dashboard';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { formatDate } from '../utils/dashboard';

export default function VocabMetrics({dictionary}) {
    const { clusteredData, toggler } = useContext(dashboardContext);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [processedData, setProcessedData] = useState([])
    const [cards, setCards] = useState({
        newWordsThisWeek: 0,
        growthPercentage: 0,
        totalCount: 0,
        mostUsedWord: '',
        mostUsedWordCount: 0
    })
    const wordsRef = useRef(null);

    const handlePointClick = (data) => {
    const clickedWeek = data?.activePayload?.[0]?.payload;
    if (clickedWeek && clickedWeek.week !==0 ) {
        setSelectedWeek(clickedWeek);
        updateCards(clickedWeek);
        setTimeout(() => {
            wordsRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }
    };

    const updateCards = (selectedWeek = null) => {
        // Determine the week to process
        const weekToProcess = selectedWeek || processedData[processedData.length - 1];
        const newWordsThisWeek = weekToProcess?.newWords?.length || 0;
        const currentWeekCount = weekToProcess?.cumulativeWords || 0;
    
        // Determine the previous week's cumulative count
        const previousWeekIndex = processedData.findIndex(week => week.week === weekToProcess.week) - 1;
        const previousWeekCount = processedData[previousWeekIndex]?.cumulativeWords || 0;
    
        const growth = ((currentWeekCount - previousWeekCount) / currentWeekCount) * 100;
        const growthPercentage = growth.toFixed(2);
    
        let mostUsedWord = '';
        let mostUsedWordCount = 0;
        if(weekToProcess) {
            const terms = clusteredData.find(item => weekToProcess.week === formatDate(new Date(item.weekStartDate), 'MMM dd, yyyy')).terms
            terms.forEach(item => {
                const word = item.term;
                const totalCount = item.notes.reduce((sum, note) => sum + note.count, 0);
        
                if (totalCount > mostUsedWordCount) {
                    mostUsedWord = word;
                    mostUsedWordCount = totalCount;
                }
            });
        }
    
        setCards({
            newWordsThisWeek,
            growthPercentage,
            totalCount: currentWeekCount,
            mostUsedWord,
            mostUsedWordCount,
        });
    };

    useEffect(() => {
        const data = clusteredData.reduce((acc, week, index) => {
            if (index === 0) {
                acc.push(
                {
                    week: 0,
                    cumulativeWords: 0,
                    newWords: [],
                },
                {
                    week: formatDate(new Date(week.weekStartDate), 'MMM dd, yyyy'),
                    cumulativeWords: week.newWords.length,
                    newWords: week.newWords,
                }
                );
            } else {
                const cumulativeCount =
                acc[acc.length - 1].cumulativeWords + week.newWords.length;
                acc.push({
                week: formatDate(new Date(week.weekStartDate), 'MMM dd, yyyy'),
                cumulativeWords: cumulativeCount,
                newWords: week.newWords,
                });
            }
            return acc;
            }, []);
        setProcessedData(data)
    },[dictionary]);

    useEffect(() => {
        updateCards()
    }, [processedData])


    return (
    <div style={{margin: "20px"}}>
        {/* <h2>Vocabulary Growth</h2> */}
        <div ref={wordsRef}>
            <div className='cards-container'>
                <div className='card'>
                    <h3>New {toggler ? 'Phrases' : 'Words'} Discovered</h3>
                    <h2>{cards.newWordsThisWeek}</h2>
                    <p>Total new {toggler ? 'phrases' : 'words'} used this week.</p>
                </div>

                <div className='card'>
                    <h3>Growth</h3>
                    <h2>+{cards.growthPercentage}%</h2>
                    <p>Among total <span style={{color: '#5C84A8', fontWeight: 600}}>{cards.totalCount}</span> unique words used</p>
                </div>

                <div className='card'>
                    <h3>Most Frequent {toggler ? 'Phrase' : 'Word'}</h3>
                    <h2>{cards.mostUsedWord}</h2>
                    <p>Used <span style={{color: '#5C84A8', fontWeight: 600}}>{cards.mostUsedWordCount}</span> number of times</p>
                </div>
            </div>
            <div>
                {selectedWeek && (
                    <div className='new-words-container'>
                        <h3 className='text-primary'>New {toggler ? 'Phrases' : 'Words'} for the Week Starting on {selectedWeek.week}</h3>
                        <ul>
                            {selectedWeek.newWords.map((word, index) => (
                                <li key={index}>{word}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
        <ResponsiveContainer width="100%" height={500}>
            <LineChart
                data={processedData}
                margin={{ top: 20, right: 30, left: 20, bottom: 40 }} // Increased bottom margin
                onClick={handlePointClick}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="week"
                    label={{ value: 'Week', position: 'insideBottom', offset: -20 }}
                    tickMargin={5} // Optional: Adds margin between the X-axis labels and the chart
                />
                <YAxis
                    label={{
                        value: 'Vocabulary Growth',
                        angle: -90,
                        position: 'insideLeft',
                        offset: 0,
                    }}
                />
                <Tooltip />
                
                <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="right"
                wrapperStyle={{ top: 0, right: 0, position: 'absolute' }}
                />
                
                {/* Student's growth line */}
                <Line
                    type="monotone"
                    dataKey="cumulativeWords"
                    stroke="#8884d8"
                    strokeWidth={3}
                    activeDot={{
                        r: 8,
                    }}
                    name="Growth"
                />
            </LineChart>
        </ResponsiveContainer>
    </div>
    );
}
