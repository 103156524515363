import { gql } from "@apollo/client"

const getDictionary = gql`
    query Dictionary($authorId: ID, $communityId: ID) { 
        dictionary(authorId: $authorId, communityId: $communityId) {
            words,
            phrases
        }
    }
`

const getHistory = gql`
    query History($authorId: ID) { 
        history(authorId: $authorId) {
            id
            authorId
            noteIds
            data
        }
    }
`

const getUser = gql`
    query User($userId: ID){
        user(id: $userId) {
            id
            firstName
            lastName
            # communities {
            #     id
            #     title
            #     views {
            #         id
            #         title
            #     }
            # } 
        }
    }
`
const getCommunity = gql`
    query Community($id: ID, $userId: ID){
        community(id: $id) {
            id
            title
            views {
                id
                title
            }
            writers {
                id
                firstName
                lastName
            }
            author(userId: $userId) {
                id
                firstName
                lastName
            }
        }
    }`
const Dictionary = { getDictionary, getUser, getCommunity, getHistory }
export default Dictionary