function getISOWeekStartDate(date) {
    const dayOfWeek = date.getUTCDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
    const diffToMonday = (dayOfWeek === 0 ? -7 : 1 - dayOfWeek); // Adjust Sunday to previous Monday
    const monday = new Date(date);
    monday.setUTCDate(monday.getUTCDate() + diffToMonday); // Move to Monday
    monday.setUTCHours(0, 0, 0, 0); // Set time to midnight
    return monday;
}

function getISOWeekEndDate(weekStartDate) {
    const sunday = new Date(weekStartDate);
    sunday.setUTCDate(sunday.getUTCDate() + 6); // Move to Sunday
    sunday.setUTCHours(23, 59, 59, 999); // Set time to end of day
    return sunday;
}

function clusterByWeek(data) {
    const clusteredData = [];

    for (const wordObj of data) {
        const { term, termFirstUsed } = wordObj;
        const notes = wordObj.notes;
        const termFirstUsedDate = new Date(termFirstUsed);

        for (const note of notes) {
            const timestamp = new Date(note.timestamp);

            // Calculate standardized week start and end dates
            const weekStartDate = getISOWeekStartDate(timestamp);
            const weekEndDate = getISOWeekEndDate(weekStartDate);

            // Check if the week already exists in clusteredData
            let weekObj = clusteredData.find(weekData => {
                const weekDataStart = new Date(weekData.weekStartDate);
                return weekDataStart.getTime() === weekStartDate.getTime();
            });

            // If the week does not exist, create it
            if (!weekObj) {
                weekObj = {
                    weekStartDate: weekStartDate.toISOString(), // Store as ISO string
                    weekEndDate: weekEndDate.toISOString(), // Store as ISO string
                    newWords: [],
                    terms: []
                };
                clusteredData.push(weekObj);
            }

            if (termFirstUsedDate.getTime() >= weekStartDate.getTime() && termFirstUsedDate.getTime() <= weekEndDate.getTime()) {
                // Add term to newWords if it's not already included
                if (!weekObj.newWords.includes(term)) {
                    weekObj.newWords.push(term);
                }
            }

            // Check if the term already exists in the week
            let wordObjInWeek = weekObj.terms.find(w => w.term === term);

            // If term doesn't exist, create a new term entry
            if (!wordObjInWeek) {
                wordObjInWeek = {
                    term: term,
                    notes: [] // Initialize notes as an empty array
                };
                weekObj.terms.push(wordObjInWeek);
            }

            // Check if the note already exists for this term
            let noteObj = wordObjInWeek.notes.find(n => n.id === note.id);

            if (!noteObj) {
                // If note doesn't exist, add it
                wordObjInWeek.notes.push(note);
            }
        }
    }
    console.log(clusteredData)
    return clusteredData.sort((a, b) => new Date(a.weekStartDate) - new Date(b.weekStartDate));
}

// Function to get week number
function getWeekNumber(date) {
    const d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOffset = startOfMonth.getDay();

    return Math.floor((d.getDate() + dayOffset) / 7) + 1;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { 
        timeZone: 'UTC',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    
    return date.toLocaleDateString('en-US', options);
}

export { clusterByWeek, formatDate }