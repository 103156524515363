import { useLocation } from "react-router-dom";
import Dictionary from "../Queries/Dictionary";
import Main from "../components/Main";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { formatDate } from "../utils/dashboard";
import dashboardContext from "../context/dashboard";
import History from "../components/History";
import '../utils/style.css';
import { TbHomeStats } from "react-icons/tb";
import { SiDictionarydotcom } from "react-icons/si";
import { GiProgression } from "react-icons/gi";
import { BsChatSquareText } from "react-icons/bs";


const Dashboard = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId')
    const role = queryParams.get('role')
    const communityId = queryParams.get('communityid')
    const { data: communityResult, loading: cLoading } = useQuery(Dictionary.getCommunity, { variables: { id: communityId, userId: userId } })
    const community = communityResult?.community
    const studentsResult = role === 'writer' ? communityResult?.community.author : communityResult?.community.writers
    const [selectedUser, setSelectedUser] = useState()
    const [toggler, setToggler] = useState(false)

    const getDictionary = (event) => {
        setSelectedUser(event.target.value)
    }
    const [active, setActive] = useState('Dashboard')
    const [clusteredData, setClusteredData] = useState({})
    const [selectedWeek, setSelectedWeek] = useState('')
    if (cLoading)
        return <div className="flex-center h-full">Loading....</div>

    return (
        <dashboardContext.Provider 
            className = "root"
            value = {{ 
                selectedUser: selectedUser, 
                role: role, userId: userId, 
                communityId: communityId, 
                selectedWeek: selectedWeek, 
                community: community, 
                studentsResult: studentsResult, 
                clusteredData: clusteredData, 
                active: active, 
                setClusteredData: setClusteredData, 
                setSelectedWeek: setSelectedWeek,
                toggler,
                setToggler
            }}
        >
            <div className="header">
                <h1>{community ? community.title : 'Loading Community...'}</h1>
                {role === 'writer'
                    ? <h4>{studentsResult?.firstName} {studentsResult?.lastName}</h4>
                    
                    : <select id='student-select' onChange={getDictionary}>
                        {studentsResult?.map((student, index) => <option key={index} value={student.id}>{student.firstName} {student.lastName}</option>)}
                    </select>
                    }
            </div>
            <div className="main">
                <div className='sidebar'>
                    <div className='main-sidebar' style={{ backgroundColor: active === 'Dashboard' ? '#9B80BC' : '', color: active === 'Dashboard' ? '#ffffff' : '#333333' }} onClick={() => setActive('Dashboard')}>
                        <TbHomeStats />
                        <span>Dashboard</span>
                    </div>
                    <div className='main-sidebar' style={{ backgroundColor: active === 'Dictionary' ? '#9B80BC' : '', color: active === 'Dictionary' ? '#ffffff' : '#333333' }} onClick={() => setActive('Dictionary')}>
                        <SiDictionarydotcom />
                        <span>Word Dictionary</span>
                    </div>
                    <div className='main-sidebar' style={{ backgroundColor: active === 'Progress' ? '#9B80BC' : '', color: active === 'Progress' ? '#ffffff' : '#333333' }} onClick={() => setActive('Progress')}>
                        <GiProgression />
                        <span>Weekly Progress</span>
                    </div>
                    {active === 'Progress' && <div style={{ marginLeft: 20, marginTop: 10 }}>
                        {
                            clusteredData.map((cluster, index) => {
                                return (
                                    <div key={index} style={{ backgroundColor: cluster.weekStartDate === selectedWeek ? '#9B80BC' : '', color: cluster.weekStartDate === selectedWeek ? '#ffffff' : '#333333' }} onClick={() => setSelectedWeek(cluster.weekStartDate)} className='nested-sidebar'>
                                        {formatDate(cluster.weekStartDate) + ' - ' + formatDate(cluster.weekEndDate)}
                                    </div>
                                )
                            }
                            )
                        }
                    </div>}
                    <div className='main-sidebar' style={{ backgroundColor: active === 'History' ? '#9B80BC' : '', color: active === 'History' ? '#ffffff' : '#333333', margin: '15px 0' }} onClick={() => setActive('History')}>
                        <BsChatSquareText />
                        <span>Chat History</span>
                    </div>
                </div>
                <div className="content">
                    { active === 'History' ? <History/> : <Main />}
                </div>
            </div>
        </dashboardContext.Provider>
    );
}

export default Dashboard;